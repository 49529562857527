<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-6 medium-3">
        <button @click="logOut" class="button">Log Out</button>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="small-12">
        <h1>
          Team members
          <router-link to="/add-member">
            <button style="margin-top:11px" class="button float-right">Add New</button>
          </router-link>
        </h1>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="small-12">
        <table>
          <thead>
            <th>Name</th>
            <th>Title</th>
            <th>Statement</th>
            <th>BW Image</th>
            <th>Col Image</th>
            <th>&nbsp;</th>
          </thead>
          <tbody>
            <tr v-for="(member, index) in teamMembers" :key="index">
              <td>{{ member.name }}</td>
              <td>{{ member.title }}</td>
              <td>{{ member.statement }}</td>
              <td><img class="list-image" :src="member.bw_image" alt="" /></td>
              <td><img class="list-image" :src="member.colour_image" alt="" /></td>
              <td>
                <button
                  @click="deleteMember(index)"
                  class="button small">
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Localbase from 'localbase';
import axios from '../axios';

export default {
  name: 'Start',
  data() {
    return {
      teamMembers: [],
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    logOut() {
      const db = new Localbase('db');
      db.config.debug = false;
      db.delete().then(() => {
        this.$store.commit('unsetCredentials');
        window.location.replace('/');
      });
    },
    getTeamMembers() {
      axios.get(`/teamMembers/fetchAll.json?token=${this.token}`)
        .then((response) => {
          this.teamMembers = response.data;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    deleteMember(index) {
      const memberId = this.teamMembers[index].id;
      axios.get(`/teamMembers/delete/${memberId}.json?token=${this.token}`)
        .then(() => {
          this.teamMembers.splice(index, 1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getTeamMembers();
  },
};
</script>

<style lang="scss">
.list-image {
  max-width: 75px;
}
</style>
